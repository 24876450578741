import React from "react"
import axios from "axios"
import { graphql } from "gatsby"
import SnackbarProvider from "react-simple-snackbar"
import { Layout, Loader, LoaderContainer, Seo } from "../../components/Shared"
import { BoilerSuggestions, MoreInfoNeeded } from "../../components/BoilerQuote"

const QuoteResult = ({ data, location }) => {
  const [quote, setQuote] = React.useState()
  const [quoteError, setQuoteError] = React.useState(false)

  React.useEffect(() => {
    const locationArray = window.location.href.split("?quote=")
    const quoteId = locationArray[locationArray.length - 1]

    const getQuoteDetails = async () => {
      try {
        const getQuote = await axios({
          method: "get",
          url: `${process.env.GATSBY_API_BASE_URL}/quote/${quoteId}`,
        })
        localStorage.setItem("quoteId", quoteId)
        setQuote(getQuote.data)
        window.scrollTo(0, 0)
      } catch (error) {
        console.log(error)
        setQuoteError(true)
      }
    }

    getQuoteDetails()
  }, [])
  return (
    <Layout minimal={true}>
      <Seo
        url={data.site && data.site.siteMetadata.url + "/quote/result"}
        title="Recommended Boilers For Your Home | Home Assist Repairs and Servicing"
      />
      {quote ? (
        quote.moreInfoNeeded ? (
          <SnackbarProvider>
            <MoreInfoNeeded quoteId={quote.id} location={location} />
          </SnackbarProvider>
        ) : (
          <BoilerSuggestions quote={quote} />
        )
      ) : (
        <LoaderContainer>
          <Loader
            text="Finding quote"
            error={quoteError}
            errorText="Error finding quote"
          />
        </LoaderContainer>
      )}
    </Layout>
  )
}

export default QuoteResult

export const pageQuery = graphql`
  query GetSiteURLQuoteResult {
    site {
      siteMetadata {
        url
      }
    }
  }
`
